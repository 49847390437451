import { useGetNoiseVibration } from 'generated/backendComponents'
import { usePrefetch, useWindowResize } from 'src/hooks'
import { Presenter as HorizontalPresenter } from './Horizontal/Presenter'
import { Presenter as VerticalPresenter } from './Vertical/Presenter'
import { Presenter as TabletHorizontalPresenter } from './TabletHorizontal/Presenter'
import { Presenter as TabletVerticalPresenter } from './TabletVertical/Presenter'
import { Presenter as SquarePresenter } from './Square/Presenter'
// import { useSubscribe } from './hooks'
import { useEffect, useState } from 'react'
import { ExtendedNoiseVibration } from './types'
import { Loading } from 'src/components/atoms/Loading'
import { useSizeStore } from 'src/zustand/useSizeStore'

// AXIOS
import axios from "axios"
import { useQuery } from '@tanstack/react-query'
// import mqtt from 'mqtt'

//
import { useMqtt } from './mqtt-hooks';


// MQTT
const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8);
const mqttUri = import.meta.env.VITE_MQTT_URL + '?x-amz-customauthorizer-name=envis-' + import.meta.env.VITE_STAGE + '-authorizer';
const options = {
  keepalive: 60,
  clientId: clientId,
  clean: true, 
  reconnectPeriod: 1000, 
  connectTimeout: 30 * 1000,
}

type Props = {
  slideIndex: number
  contentId: number
  isActive: boolean
}

export const NoisePage = ({ contentId, slideIndex, isActive }: Props) => {
  const { size } = useSizeStore()
  const [isLoading, setIsLoading] = useState(true);
  const [credentials, setCredentials] = useState<{accessKey: string, serialNumber: string, contractName: string} | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [receivedMessages, setReceivedMessages] = useState<string[]>([]);
  const [vndata, setVndata] = useState<ExtendedNoiseVibration>({
    noiseValue: '0',
    vibrationValue: '0',
    contractName: '',
    areaName: ''
  })

  const type = 'default' as any

  // MQTTフックをnullで初期化（認証情報取得前）
  const mqtt = useMqtt(
    mqttUri,
    '',
    ''
  );

  // APIからアクセスキーを取得
  useEffect(() => {
    async function fetchCredentials() {
      setIsLoading(true);
      try {
        const response = await fetch(import.meta.env.VITE_API_URL + '/api/contents/vnmeter/' + contentId);
        if (!response.ok) {
          throw new Error('認証情報の取得に失敗しました');
        }
        const data = await response.json();

        console.log(data)
        
        setCredentials({
          accessKey: data.accessKey,
          serialNumber: data.serialNumber,
          contractName: data.contractName
        });
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : '認証情報の取得中にエラーが発生しました');
        console.error('認証情報の取得エラー:', err);
      } finally {
        setIsLoading(false);
      }
    }
    
    if (mqtt.connectionStatus === 'disconnected') {
      fetchCredentials()
    }
    
    return () => {
    }
  }, [mqtt.connectionStatus]);

  // 認証情報を取得したらMQTTに接続
  useEffect(() => {
    if (credentials && !isLoading) {
      mqtt.connect(
        credentials.serialNumber,
        credentials.accessKey,
        options
      );
    }
    
    return () => {
      mqtt.disconnect();
    };
  }, [credentials, isLoading]);

  // 接続状態とメッセージ受信の監視
  useEffect(() => {
    // トピックを購読
    if (mqtt.connectionStatus === 'connected') {
      mqtt.subscribe('envis-' + import.meta.env.VITE_STAGE + '/data/1/' + credentials?.serialNumber);
    }
    
    // 新しいメッセージを受信したら表示
    if (mqtt.lastMessage) {
/**
      setReceivedMessages(prev => [...prev, 
        `${mqtt.lastMessage?.topic}: ${mqtt.lastMessage?.message}`
      ]);
 */
      setVndata({
        noiseValue: Math.round( parseFloat(mqtt.lastMessage.message.n) ).toString(),
        vibrationValue: Math.round( parseFloat(mqtt.lastMessage.message.v) ).toString(),
        contractName: credentials?.contractName,
        areaName: ''
      })

    }
  }, [mqtt.connectionStatus, mqtt.lastMessage]);

  return (
    <>
      { (size === 'square') && 
        <SquarePresenter {...{ type, vndata }} />
      }
      { (size === 'default') && 
        <HorizontalPresenter {...{ type, vndata }} />
      }
      { (size === 'tablethorizontal') && 
        <TabletHorizontalPresenter {...{ type, vndata }} />
      }
      { (size === 'vertical') && 
        <VerticalPresenter {...{ type, vndata }} />
      }
      { (size === 'tabletvertical') && 
        <TabletVerticalPresenter {...{ type, vndata }} />
      }
    </>
  )
}
