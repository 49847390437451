// mqtt-hooks.ts
import { useState, useEffect, useCallback, useRef } from 'react';
import mqtt, { MqttClient, IClientOptions, IClientSubscribeOptions } from 'mqtt';

interface MqttState {
  client: MqttClient | null;
  connectionStatus: 'connected' | 'disconnected' | 'connecting' | 'reconnecting' | 'error';
  lastMessage: {
    topic: string;
    message: {
      v: string,
      n: string
    };
  } | null;
}

interface MqttContextValue extends MqttState {
  connect: (serialNumber: string, accessKey: string,options?: IClientOptions) => void;
  disconnect: () => void;
  subscribe: (topic: string, options?: IClientSubscribeOptions) => void;
  unsubscribe: (topic: string) => void;
  publish: (topic: string, message: string, options?: any) => void;
}

/**
 * React hook for using MQTT in Next.js applications.
 * 
 * @param brokerUrl - URL of the MQTT broker (e.g., 'mqtt://broker.example.com')
 * @param options - MQTT client options
 * @returns MQTT context value with client state and methods
 */
export const useMqtt = (
  brokerUrl: string,
  serialNumber: string,
  accessKey: string,
  initialOptions?: IClientOptions
): MqttContextValue => {
  const [state, setState] = useState<MqttState>({
    client: null,
    connectionStatus: 'disconnected',
    lastMessage: null,
  });

  // Store message handlers in a ref to avoid unnecessary re-renders
  const messageHandlers = useRef<Map<string, (message: string) => void>>(new Map());
  
  // Connect to MQTT broker
  const connect = useCallback((serialNumber: string, accessKey: string, options?: IClientOptions) => {
    if (state.client && state.connectionStatus !== 'disconnected') {
      return;
    }
    
    // Combine initial options with any new options
    const clientOptions = { ...initialOptions, ...options };
    
    // Client-side only code
    if (typeof window !== 'undefined') {
      setState(prev => ({ ...prev, connectionStatus: 'connecting' }));
      const url = brokerUrl + '&sn=' + serialNumber + '&key=' + accessKey
      const client = mqtt.connect(url, clientOptions)

      client.on('connect', () => {
        setState(prev => ({ ...prev, client, connectionStatus: 'connected' }));
      });
      
      client.on('reconnect', () => {
        setState(prev => ({ ...prev, connectionStatus: 'reconnecting' }));
      });
      
      client.on('error', (err) => {
        console.error('MQTT connection error:', err);
        setState(prev => ({ ...prev, connectionStatus: 'error' }));
      });
      
      client.on('offline', () => {
        setState(prev => ({ ...prev, connectionStatus: 'disconnected' }));
      });
      
      client.on('message', (topic, message) => {
        const messageString = JSON.parse(message.toString())

        setState(prev => ({
          ...prev,
          lastMessage: {
            topic,
            message: messageString
          }
        }));
        
        // Call any registered handlers for this topic
        const handler = messageHandlers.current.get(topic);
        if (handler) {
          handler(messageString);
        }
      });
      
      setState(prev => ({ ...prev, client }));
    }
  }, [brokerUrl, initialOptions, state.client, state.connectionStatus]);
  
  // Disconnect from MQTT broker
  const disconnect = useCallback(() => {
    if (state.client) {
      state.client.end();
      setState(prev => ({
        ...prev,
        client: null,
        connectionStatus: 'disconnected'
      }));
    }
  }, [state.client]);
  
  // Subscribe to an MQTT topic
  const subscribe = useCallback((
    topic: string,
    options?: IClientSubscribeOptions
  ) => {
    if (state.client && state.connectionStatus === 'connected') {
      state.client.subscribe(topic, options || { qos: 0 });
    }
  }, [state.client, state.connectionStatus]);
  
  // Unsubscribe from an MQTT topic
  const unsubscribe = useCallback((topic: string) => {
    if (state.client && state.connectionStatus === 'connected') {
      state.client.unsubscribe(topic);
    }
  }, [state.client, state.connectionStatus]);
  
  // Publish a message to an MQTT topic
  const publish = useCallback((
    topic: string,
    message: string,
    options?: any
  ) => {
    if (state.client && state.connectionStatus === 'connected') {
      state.client.publish(topic, message, options || { qos: 0, retain: false });
    }
  }, [state.client, state.connectionStatus]);
  
  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (state.client) {
        state.client.end();
      }
    };
  }, [state.client]);
  
  return {
    ...state,
    connect,
    disconnect,
    subscribe,
    unsubscribe,
    publish
  };
};

/**
 * React hook for subscribing to specific MQTT topics
 * 
 * @param client - MQTT client instance
 * @param topic - MQTT topic to subscribe to
 * @param options - Subscription options
 * @returns The latest message received on the topic
 */
export const useSubscription = (
  client: MqttClient | null,
  topic: string,
  options?: IClientSubscribeOptions
): string | null => {
  const [message, setMessage] = useState<string | null>(null);
  
  useEffect(() => {
    if (!client || !topic) return;
    
    const messageHandler = (receivedMessage: Buffer) => {
      setMessage(receivedMessage.toString());
    };
    
    client.subscribe(topic, options || { qos: 0 });
    client.on('message', (receivedTopic, receivedMessage) => {
      if (receivedTopic === topic) {
        messageHandler(receivedMessage);
      }
    });
    
    return () => {
      client.unsubscribe(topic);
    };
  }, [client, topic, options]);
  
  return message;
};